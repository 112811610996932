








import KCard from '@/@core/components/other/KCard.vue';
import KText from '@/@core/components/typography/KText.vue';
import {
  defineComponent,
  onMounted,
  PropType
} from '@vue/composition-api';
import SingleOtherCostForm from './SingleOtherCostForm.vue';

export default defineComponent({
  components: { KText, SingleOtherCostForm, KCard },
  name: 'OtherCostsForm',
  props: {
    data: {
      type: Object as PropType<any>,
      required: true
    }
  },
  setup(props) {
    const defaultOtherCostsData = {
      subject: '',
      needsPerMonth: null,
      pricePerUnit: null,
      totalNeedsPerMonth: null,
      totalNeedsPerYear: null
    };

    onMounted(() => {
      const propsData: any = props.data;
      const hasOtherCosts = propsData.otherCosts?.length;

      if (hasOtherCosts) return;

      propsData.otherCosts = [{ ...defaultOtherCostsData }];
    });

    return {
      defaultOtherCostsData
    };
  }
});
