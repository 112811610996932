var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"column":""}},[_c('k-text',{staticClass:"title mt-n1",attrs:{"text":"Biaya Lainnya"}}),_c('k-array-input',{attrs:{"defaultValue":{
      subject: '',
      needsPerMonth: null,
      pricePerUnit: null,
      totalNeedsPerMonth: null,
      totalNeedsPerYear: null
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var item = ref.item;
    var itemIndex = ref.index;
return [_c('k-text-input',{attrs:{"label":"Nama Operasional"},model:{value:(item.subject),callback:function ($$v) {_vm.$set(item, "subject", $$v)},expression:"item.subject"}}),_c('k-text-input',{attrs:{"label":"Jumlah Kebutuhan Perbulan","type":"number"},on:{"input":function($event){return _vm.calculateTotalNeeds(item)}},model:{value:(item.needsPerMonth),callback:function ($$v) {_vm.$set(item, "needsPerMonth", _vm._n($$v))},expression:"item.needsPerMonth"}}),_c('k-combobox-input',{attrs:{"label":"Satuan","placeholder":"Pilih satuan","item-text":"name","item-value":"name","items":_vm.unitStore.units},model:{value:(item.unit),callback:function ($$v) {_vm.$set(item, "unit", $$v)},expression:"item.unit"}}),_c('k-currency-input',{attrs:{"label":"Harga Satuan","prefix":"Rp. "},on:{"input":function($event){return _vm.calculateTotalNeeds(item)}},model:{value:(item.pricePerUnit),callback:function ($$v) {_vm.$set(item, "pricePerUnit", $$v)},expression:"item.pricePerUnit"}}),_c('k-currency-input',{key:'needs-per-month-' + itemIndex + '-' + _vm.reRenderCount,attrs:{"readonly":"","label":"Total Kebutuhan Perbulan","prefix":"Rp. "},model:{value:(item.totalNeedsPerMonth),callback:function ($$v) {_vm.$set(item, "totalNeedsPerMonth", $$v)},expression:"item.totalNeedsPerMonth"}}),_c('k-currency-input',{key:'needs-per-year-' + itemIndex + '-' + _vm.reRenderCount,attrs:{"readonly":"","label":"Total Kebutuhan Pertahun","prefix":"Rp. "},model:{value:(item.totalNeedsPerYear),callback:function ($$v) {_vm.$set(item, "totalNeedsPerYear", $$v)},expression:"item.totalNeedsPerYear"}})]}}]),model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }